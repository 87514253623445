import type { PlatformLogger, ControllerDataItem, AppModule, ClientSpecMapAPI, PlatformEnvData, ModuleLoader } from '@wix/thunderbolt-symbols'
import type { IAppsUrls } from './appsUrls'
import { getControllerNameFromUrl } from './getControllerNameFromUrl'

export interface Container {
	init(): Promise<void>
	get(name: string): Promise<() => AppModule>
}

export interface ModuleFederationManager {
	getControllerNameFromUrl(controllerScriptUrl: string): string
	loadAppModule(appDefinitionId: string, viewerScriptUrl: string): Promise<AppModule | null>
	loadControllerModule({ controllerType, applicationId }: Pick<ControllerDataItem, 'controllerType' | 'applicationId'>, viewerScriptUrl: string): Promise<any>
}

export function ModuleFederationManagerFactory({
	logger,
	moduleLoader,
	appsUrlsManager,
	clientSpecMapApi,
	platformEnvData: {
		site: { experiments },
	},
}: {
	logger: PlatformLogger
	moduleLoader: ModuleLoader
	appsUrlsManager: IAppsUrls
	clientSpecMapApi: ClientSpecMapAPI
	platformEnvData: PlatformEnvData
}): ModuleFederationManager {
	const isModuleFederated = (appDefinitionId: string) => experiments['specs.thunderbolt.module_federation'] && clientSpecMapApi.isModuleFederated(appDefinitionId)

	const loadModuleWithModuleFederation = async (viewerScriptUrl: string, appDefinitionId: string, moduleName: string) => {
		const webworkerContainerUrl = viewerScriptUrl.replace('viewerScript.bundle', `webworkerContainer${moduleName}`)
		const container = await moduleLoader.loadModule<Container>(webworkerContainerUrl)
		// Initializes the share scope. This fills it with known provided modules from this build and all remotes
		// This will be replaced by using a shared scope of our own
		// @ts-ignore
		await __webpack_init_sharing__('default')
		// @ts-ignore
		await container.init(__webpack_share_scopes__.default)
		const moduleFactory = await container.get(moduleName)
		const federatedModule = moduleFactory()
		return federatedModule
	}

	return {
		getControllerNameFromUrl,
		async loadAppModule(appDefinitionId, viewerScriptUrl) {
			const loadMethod = isModuleFederated(appDefinitionId)
				? () => loadModuleWithModuleFederation(viewerScriptUrl, appDefinitionId, 'viewerScript')
				: () => moduleLoader.loadModule<AppModule>(viewerScriptUrl)
			return logger.withReportingAndErrorHandling('script_loaded', loadMethod, { appDefinitionId })
		},
		async loadControllerModule({ controllerType, applicationId: appDefinitionId }, viewerScriptUrl) {
			const controllerScriptUrl = appsUrlsManager.getControllerScriptUrl(appDefinitionId, controllerType)
			if (!controllerScriptUrl) {
				return null
			}

			const loadMethod = isModuleFederated(appDefinitionId)
				? () => loadModuleWithModuleFederation(viewerScriptUrl, appDefinitionId, getControllerNameFromUrl(controllerScriptUrl))
				: () => moduleLoader.loadModule(controllerScriptUrl)
			return logger.withReportingAndErrorHandling('script_loaded', loadMethod, { appDefinitionId, controllerType })
		},
	}
}
